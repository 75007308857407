import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="arkivlista col-sm-10">
      <h1>Utlånad till annan myndighet</h1>
      <span className="ingress">
        Personallån är en möjlighet inom Rörlighet i staten (RIS). Det är ett
        sätt för dig att prova på att jobba på en annan myndighet, till exempel
        att få insyn inom ett annat område, projekt eller process än du
        vanligtvis jobbar med. Förutom att lära sig mer inom en viss
        arbetsuppgift eller område brukar den som lånats in hos en annan
        myndighet se det som en stor utveckling rent personligen, även om lånet
        uppstår utifrån verksamhetsbehov. Det är en chans för dig som individ
        att lära dig nya saker, få ny erfarenhet och inspiration, som du sen tar
        med dig tillbaka ”hem” till din ordinarie arbetsplats igen. Se gärna
        filmen för att få en större inblick i hur det kan vara att bli utlånad
        via Rörlighet i staten.
      </span>
      <br />
      <iframe
        className="streamio-player"
        src="https://streamio.com/api/v1/videos/6703f5256f8d8d9fc6000009/public_show?player_id=59eed3d56f8d8d20b5000001"
        title="RIS Personallån"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>

      <br />
      <br />
      <p></p>
      <h2>Ansökan</h2>
      <p>
        Innan du söker ett uppdrag stämmer du av med din chef om möjligheten att
        vara utlånad. Är ni överens skickar du in din ansökan med CV och
        personligt brev på samma sätt som när du söker ett jobb. Motivera varför
        du är intresserad och hur du kan bidra till verksamheten.
      </p>
      <p>
        Via myndigheternas HR-funktioner upprättas en överenskommelse. Utlånet
        varar cirka sex månader. Om något skulle inträffa under utlåningen kan
        överenskommelsen brytas av båda parter med en uppsägningstid på en
        månad.
      </p>
      <p>
        Här kan du läsa{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/">
          erfarenheter från medarbetare som provat på personallån.
        </Link>
      </p>
      <h2>Anställningsform och lön när du är utlånad</h2>
      <p>
        Utlåningen påverkar inte din anställning eller lön. Du är fortsatt
        anställd hos din myndighet som betalar ut din lön som vanligt. Du
        registrerar din arbetstid i ordinarie rapporteringssystem. Myndigheten
        som du är utlånad till ansvarar för arbetsledning och din arbetsmiljö.
      </p>
      <h2>Mer information</h2>
      <p>
        Vill du veta mera om att vara utlånad, kontakta HR-funktionen på din
        myndighet eller vår samordnare Christina Frimodig,
        christina.frimodig@naturvardsverket.se eller 070-673 47 47.
      </p>
      <h2>Aktuella uppdrag</h2>
      <p>
        Här listar vi aktuella förfrågningar från myndigheter som vill låna in
        en person för ett kortare uppdrag. Klicka på respektive rubrik för mer
        info.&nbsp;
      </p>
      <p>
        Finns det ingen förfrågan eller inget som passar dig, ta gärna ett eget
        initiativ och hör av dig till Rörlighet i staten.
      </p>

      <div className="pb-3">
        <h4>
          <a
            href="/media/1501/AnnonspersonallanRorlighetistatenRegistratorTVV25.pdf"
            title="Tillväxtverket söker registrator för inlån"
          >
            Tillväxtverket söker registrator för inlån
            <span>&nbsp;</span>
          </a>
        </h4>
        <p>
          <span>
            Prova på att jobba på Tillväxtverket. De söker just nu en
            registrator för inlån.{" "}
          </span>
          <a href="/media/1501/AnnonspersonallanRorlighetistatenRegistratorTVV25.pdf">
            Läs mer om tjänsten här (pdf)
          </a>
        </p>
      </div>
      <div className="pb-3">
        <h4>
          <a
            href="/media/1501/AnnonspersonallanJuristFormas.pdf"
            title="Formas söker jurist för inlån"
          >
            Formas söker jurist för inlån
            <span>&nbsp;</span>
          </a>
        </h4>
        <p>
          <span>
            Prova på att jobba på Formas. De söker just nu en Jurist för inlån.{" "}
          </span>
          <a href="/media/1501/AnnonspersonallanJuristFormas.pdf">
            Läs mer om tjänsten här (pdf)
          </a>
        </p>
      </div>

      <ul></ul>
    </div>
  );
}
export default Page;
