import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Introduktionsutbildning: Att arbeta som statligt anställd&nbsp;</h1>
      <span className="ingress">
        Utbildningen är en grundläggande introduktion för dig&nbsp;som är
        ny&nbsp;som statligt anställd, eller känner att du vill fräscha upp dina
        kunskaper.
      </span>
      <br />
      <br />
      <h3>Om utbildningen</h3>
      <p>
        <span>Den offentliga förv</span>alt
        <span>
          ningen kan inte fungera utan ett högt förtroende från allmänheten. En
          god tillit till myndigheternas arbete ger också förutsättningar för en
          välfungerande demokrati och stärker samhällets förmåga att lösa
          gemensamma utmaningar.{" "}
        </span>
      </p>
      <p>
        Under utbildningen får du diskutera vad det innebär att arbeta statligt
        och tillsammans med andra deltagare reflektera över vad det betyder för
        dig och ditt arbete.&nbsp;
        <span>
          Vi kommer även att prata om varför korruption är skadligt för
          samhället och presentera den statliga värdegrundens sex principer och
          lagstiftning som ligger bakom.{" "}
        </span>
        Utbildningen bygger på dialog, dilemmaövningar och ditt aktiva
        deltagande.&nbsp;
      </p>
      <p>
        <span>
          Utbildningen är öppen för medarbetare på Elsäkerhetsverket,
          Konkurrensverket, Rymdstyrelsen, Formas, SBU, Mediemyndigheten samt
          Spelinspektionen.
        </span>
      </p>
      <p>&nbsp;</p>
      <h3>
        <span>Mer information och anmälan</span>
      </h3>
      <p>
        <span>
          Vill du veta mera eller anmäla dig till utbildningen? Kontakta Nicklas
          Vabi, Rymdstyrelsen. Förnamn.efternamn@snsa.se Välkommen!
        </span>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
