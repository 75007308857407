import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Inspirationsföreläsningar och erfarenhetsutbyte</h1>
      <span className="ingress">
        Rörlighet i staten erbjuder digitala inspirationsföreläsningar och
        digitala erfarenhetsutbyte, så kallade popup-samtal för alla medarbetare
        oavsett roll i myndigheter som är medlemmar i Rörlighet i staten.
      </span>
      <br />
      <br />

      <p>&nbsp;</p>
      <h2>Inspirationsföreläsningar</h2>
      <br />
      <h5>Föreläsningsprogrammet läggs ut på webbsidan inom kort.</h5>

      <br />
      <div>
        <p>
          Har du frågor kontakta Cecilia Carenfelt, Statens Historiska Museer.
          Förnamn.efternamn@shm.se
        </p>
      </div>
      <p>&nbsp;</p>

      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
